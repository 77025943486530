<template>
	<div>
		<!-- 头部logo -->
		<moveHeadLogo @navigation="navigation"></moveHeadLogo>
		
		<!-- 头部导航 -->
		<moveNavigation :headTitle="headTitle" :headImg="headImg"></moveNavigation>
		
		<!-- 一级切换 -->
		<div class="programsM">
			<el-row class="programsM-box">
				<el-col :span="12" :class="[this.value==1?'highlightsProM-yes':'highlightsProM-no']">
					<div @click="seasonal">
						HIGHLIGHTS
					</div>
				</el-col>
				<el-col :span="12" :class="[this.value==2?'performancesProgM-yes':'performancesProgM-no']">
					<div @click="performances">
						PERFORMANCES
					</div>
				</el-col>
			</el-row>
			
			<el-row id="seaScoll">
				<el-row v-if="isseaData">
					<!-- seasonal signature 数据 -->
					<el-row class="newsProM-databox">
						<el-col :span="12" v-for="(item,id) in seaListData" :key="id">
							<div @click="newsDetails(item)">
								<el-row class="evenas-data-extend">
									<img :src="eventsNotLoadImage" :onload="onLoadImg(item.pictureUrl)" class="eventsdata-img"></img>
									<el-image class="evenasdata-coverageimg" src="/home/homeEventMcoverage.png"></el-image>
									<el-image class="evenasdata-logoimg" src="/home/logo.png"></el-image>
									<div class="evenasdata-titleMessage">
										<el-row class="evenasdata-title">{{item.title}}</el-row>
										<el-row v-html="item.bodyContent" class="evenasdata-message">{{item.bodyContent}}</el-row>
									</div>	
									<el-row class="evenasdata-date">
										<el-image class="theaterProM-chainimg" src="/home/chain.png"></el-image>
									</el-row>
								</el-row>
							</div>
						</el-col>
					</el-row>
					<!-- 按钮 -->
					<el-row>
						<button v-if="sealoading" class="productionsM-btn" @click="seaMore">Load More</button>
					</el-row>
				</el-row>
				<el-row v-else>
					<el-row>
						<el-image src="/index/nodata.png" class="error-img"></el-image>
					</el-row>
					<el-row class="error-message">
						Empty data
					</el-row>
				</el-row>
			</el-row>
			
			<el-row id="perScoll">
					
				<!-- performances 切换 -->
				<el-row class="productionsM-concertbox">
					<div class="productionsM-concert">
						<div :class="[this.productionscss==1?'theaterProM-yes':'theaterProM-no']" @click="concert">
							CONCERT
						</div>
						<div :class="[this.productionscss==2?'theaterProM-yes':'theaterProM-no']" @click="theatre">
							THEATRE
						</div>
						<div :class="[this.productionscss==3?'theaterProM-yes':'theaterProM-no']" @click="ballet">
							BALLET AND DANCE
						</div>
						<div :class="[this.productionscss==4?'theaterProM-yes':'theaterProM-no']" @click="musical">
							MUSICAL
						</div>
						<div :class="[this.productionscss==5?'theaterProM-yes':'theaterProM-no']" @click="operaTraditional">
							OPERA AND CHINESE TRADITIONAL OPERA
						</div>
					</div>
					
					<!-- 二级切换数据 -->
					<el-row v-if="isperData">
						<el-row class="newsProgramsListMOne">
							<el-col :span="12" v-for="(item,id) in perListData" :key="id">
								<div @click="details(item)" class="newsM-dataone">
									<img :src="newsNotLoadImage" :onload="onLoadImg(item.pictureUrl)" class="newsMdata-img"></img>
									<el-image class="newsMdata-coverageimg" src="/home/homeEventMcoverage.png"></el-image>
									<el-image class="newsMdata-logoimg" src="/home/logo.png"></el-image>
									<div class="newsMdata-titleMessage">
										<el-row class="newsMdata-title">{{item.title}}</el-row>
										<el-row class="newsMdata-date">
											<span>{{item.time}}</span>
											<span>{{item.month}}</span>
											<span>{{item.year}}</span>
										</el-row>
									</div>
								</div>
							</el-col>
						</el-row>
							<el-row>
								<button v-if="perloading" class="productionsM-btn" @click="perMore">Load More</button>
							</el-row>
					</el-row>
				
					<el-row v-else>
						<el-row>
							<el-image src="/index/nodata.png" class="error-img"></el-image>
						</el-row>
						<el-row class="error-message">
							Empty data
						</el-row>
					</el-row>
				</el-row>
			</el-row>
		</div>
		
		<!-- 菜单导航 -->
		<moveMeauDialog :meauValue="navigationvalue" @meauFn="menu"></moveMeauDialog>
		
		<!-- 底部导航 -->
		<moveBottomNavigation></moveBottomNavigation>
	</div>
</template>

<script>
	// 图片查询接口
	import { selectPictureListById , selectContentListById } from '@/api/index.js'

	// 头部组件
	import moveHeadLogo from '@/components/move/moveHeadLogo.vue'
	// 菜单导航
	import moveMeauDialog from '@/components/move/moveMeauDialog.vue'
	// 头部导航
	import moveNavigation from '@/components/move/moveNavigation.vue'
	// 底部导航
	import moveBottomNavigation from '@/components/move/moveBottomNavigation.vue'
	export default
	{
		data()
		{
			return{
				// 头部导航标题
				headTitle:'PROGRAMS',
				// 头部导航图片
				headImg:'/index/programs.png',
				// 导航值
				navigationvalue:false,
				
				value:1,
				
				// 二级数据显示条数
				seaCount:4,
				// 加载按钮
				sealoading:true,
				// 三级数据显示条数
				perCount:4,
				// 加载按钮
				perloading:true,
				
				introduce:[],
				newList:[],
				perList:[],
			
				newsListData:{},
				
				productionsvalue:1,
				productionscss:1,
				
				
				moduleType:'MODULE_CONCERT',
				
				// 浏览器
				windowData:'programs-introduce',
				
				isheiData:true,
				isseaData:true,
				isperData:true,
				higNotLoadImage: "/Mimage/640_360.jpg", //hig图片未加载出来
				eventsNotLoadImage: "/Mimage/335_252.jpg", //事件图片未加载出来
				newsNotLoadImage: "/Mimage/335_384.jpg", //新闻图片未加载出来
			}
		},
		components:
		{
			moveHeadLogo,
			moveMeauDialog,
			moveNavigation,
			moveBottomNavigation,
		},
		created()
		{
			this.getSeaList();
			this.getPerList();
		},
		mounted()
		{
			window.addEventListener('scroll', this.scrollEvent);
		},
		computed:
		{
			// 第二部分
			seaListData()
			{
				let data=[];
				if(this.newList.length>4)
				{
					data=this.newList.slice(0,this.seaCount)
					return data;
				}
				else
				{
					data=this.newList;
					this.sealoading=false;
					return data;
				}
			},
			
			// 第三部分
			perListData()
			{
				let data=[];
				if(this.perList.length>1)
				{
					data=this.perList.slice(0,this.perCount)
					return data;
				}
				else
				{
					data=this.perList;
					this.perloading=false;
					return data;
				}
			}
		},
		methods:
		{
			// 头部传来的数据
			navigation(v)
			{
				this.navigationvalue=v;
				console.log(this.navigationvalue,'头部传递菜单导航')
			},
			// 遮罩层传回来的数据
			menu(v)
			{
				this.navigationvalue=v;
			},
			// sea数据
			getSeaList()
			{
				this.isseaData=true;
				selectContentListById(
				{
				   chnanelType: 2,
				   isIndex: 0,
				   moduleType: 'MODULE_SEASONAL'
				})
				.then((response)=>
				{
					this.sealoading=true;
				 	this.newList=response.data.data;
					if(this.newList.length==0)
					{
						this.isseaData=false;
					}
				})
			},
			// per数据
			getPerList()
			{
				this.isperData=true;
				selectContentListById(
				{
				   chnanelType: 2,
				   isIndex: 0,
				   moduleType: this.moduleType
				})
				.then((response)=>
				{
					this.perloading=true;
				 	this.perList=response.data.data;
					this.perList.map((v)=>
					{
						this.time(v)
					})
					if(this.perList.length==0)
					{
						this.isperData=false;
					}
				})
			},
			time(v)
			{
				v.pulish=v.publish.split('-')
				if(v.pulish[1]=='01')
				{
					v.pulish[1]='JAN'
				}
				else if(v.pulish[1]=='02')
				{
					v.pulish[1]='FEB'
				}
				else if(v.pulish[1]=='03')
				{
					v.pulish[1]='MAR'
				}
				else if(v.pulish[1]=='04')
				{
					v.pulish[1]='APR'
				}
				else if(v.pulish[1]=='05')
				{
					v.pulish[1]='MAY'
				}
				else if(v.pulish[1]=='06')
				{
					v.pulish[1]='JUN'
				}
				else if(v.pulish[1]=='07')
				{
					v.pulish[1]='JUL'
				}
				else if(v.pulish[1]=='08')
				{
					v.pulish[1]='AUG'
				}
				else if(v.pulish[1]=='09')
				{
					v.pulish[1]='SEP'
				}
				else if(v.pulish[1]=='10')
				{
					v.pulish[1]='OCT'
				}
				else if(v.pulish[1]=='11')
				{
					v.pulish[1]='NOV'
				}
				else if(v.pulish[1]=='12')
				{
					v.pulish[1]='DEC'
				}
				v.year=v.pulish[0];
				v.month=v.pulish[1];
				v.time=v.pulish[2];
			},
			
			// 获取css高度
			getWindowData()
			{
				var PageId = document.querySelector(this.windowData)
				window.scrollTo(
				{
					'top': PageId.offsetTop,
					'behavior': 'smooth'
				})
			},
			seasonal()
			{
				this.value=1;
				this.windowData='#seaScoll'
				this.getWindowData();
			},
			performances()
			{
				this.value=2;
				this.windowData='#perScoll'
				this.getWindowData();
			},
			
			// 滑动事件
			scrollEvent()
			{
				// 获取视窗高度
				let domHight = document.body.offsetHeight;
				// dom滚动位置
				let scrollTop =
				  window.scrollY ||
				  document.documentElement.scrollTop ||
				  document.body.scrollTop;
				var PageTdArts=document.querySelector('#seaScoll')
				var PageTdOur=document.querySelector('#perScoll')
				if(scrollTop>=PageTdArts.offsetTop)
				{
					this.value=1;
				}
				if(scrollTop>=PageTdOur.offsetTop)
				{
					this.value=2;
				}
			},
			
			
			// 第二部分 加载 / 收起
			seaMore()
			{
				this.seaCount+=4;
				console.log(this.seaCount,this.newList.length)
				if(this.seaCount>=this.newList.length)
				{
					this.sealoading=false
				}
			},
			
			// 第三部分 加载 / 收起
			perMore()
			{
				this.perCount+=4;
				console.log(this.perCount,this.newList.length)
				if(this.perCount>=this.newList.length)
				{
					this.perloading=false
				}
			},
			
			// 进入详情
			details(item)
			{
				localStorage.setItem('Details',JSON.stringify(item));
				this.$router.push(
				{
					path:'/moveNoticesDetails',
					query:
					{
						item:item,
						headTitle:'PROGRAMS',
						headImg:'/index/programs.png',
					}
				})
			},
			
			// 音乐会 切换
			concert()
			{
				this.productionsvalue=1;
				this.productionscss=1;
				this.perCount=4;
				this.moduleType='MODULE_CONCERT'
				this.getPerList();
			},
			// 剧院 切换
			theatre()
			{
				this.productionsvalue=1;
				this.productionscss=2;
				this.perCount=4;
				this.moduleType='MODULE_THEATRE'
				this.getPerList();
			},
			// 芭蕾舞剧 切换
			ballet()
			{
				this.productionsvalue=1;
				this.productionscss=3;
				this.perCount=4;
				this.moduleType='MODULE_DANCE'
				this.getPerList();
			},
			// 歌剧院 切换
			musical()
			{
				this.productionsvalue=1;
				this.productionscss=4;
				this.perCount=4;
				this.moduleType='MODULE_MUSICAL'
				this.getPerList();
			},
			// 戏剧 切换
			operaTraditional()
			{
				this.productionsvalue=1;
				this.productionscss=5;
				this.perCount=4;
				this.moduleType='MODULE_OPERA'
				this.getPerList();
			},
			newsDetails(item)
			{
				localStorage.setItem('Details',JSON.stringify(item));
				this.$router.push(
				{
					path:'/moveNoticesDetails',
					query:
					{
						item:item,
						headTitle:'PROGRAMS',
						headImg:'/index/programs.png',
					}
				})
			},
			// 图片加载完成时触发
			onLoadImg(imgSrc) 
			{
				return 'this.src='+'"'+imgSrc+'";this.οnlοad=null';
			},
		}
	}
</script>

<style scoped>
	@import '../../assets/MoveHomeTwoDate.css';
	@import '../../assets/MoveNewsBox.css';
	.programsM{
		width: 96%;
		margin-left: 2%;
		margin-top: 40px;
	}
	.programsM-box{
		width: 100%;
		border: solid 2px #ccc;
		color: #505050;
		border-top-left-radius: 50px;
		border-bottom-left-radius: 50px;
		border-top-right-radius: 50px;
		border-bottom-right-radius: 50px;
		line-height: 50px;
		margin-bottom: 20px;
		font-size: 20px;
		color: #505050;
	}
	.highlightsProM-yes{
		height: 46px;
		background-color: #FCD002;
		border-top-left-radius: 50px;
		border-bottom-left-radius: 50px;
	}
	.highlightsProM-no{
		height: 46px;
		background-color: #F0EDF1;
		border-top-left-radius: 50px;
		border-bottom-left-radius: 50px;
	}
	.performancesProgM-yes{
		height: 46px;
		background-color: #FCD002;
		border-top-right-radius: 50px;
		border-bottom-right-radius: 50px;
	}
	.performancesProgM-no{
		height: 46px;
		background-color: #F0EDF1;
		border-top-right-radius: 50px;
		border-bottom-right-radius: 50px;
	}
	.productionsM-btn{
		border: none;
		border-radius: 50px;
		margin-top: 50px;
		width: 600px;
		height: 80px;
		background: #FDD000;
		font-size: 30px;
		color: #505050;
	}
	.programsM-introduce{
		width:100%;
		height:530px;
		position:relative; 
		color:#505050;
		margin-top: 30px;
	}
	.programsM-imgbox{ 
		width: 90%; 
		position:absolute; 
		border-radius: 20px;
		z-index: 34;
	} 
	.programsM-introduceimg{
		width: 640px;
		height: 360px;
		border-radius: 20px;
		z-index: 34;
	}
	.programsM-introducebox{
		width:664px;
		height: 344px;
		position:absolute;
		right:0px;
		top: 180px;
		background-color: #F6F5F8;
		border-radius: 20px;
		padding: 200px 2% 30px 5%;
		text-align: left;
		
	}
	.verticalProM-img{
		width: 4px;
		height: 103px;
		position: absolute;
		bottom: 90px;
		z-index: 99;
	}
	.programsM-introducetitle{
		height: 60px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		font-size: 30px;
		color: #505050;
		line-height:30px;
	}
	.programsM-introducemessage{
		height: 50px;
		font-size: 14px;
		line-height: 25px;
		margin-top: 20px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		height: 49px;
		font-size: 20px;
		color: #505050;
	}
	.newsProM-databox{
		margin-top: 30px;
	}
	.news-dataProM{
		width: 96%;
		margin-right: 2%;
		position: relative;
		border: solid 1px #f5f5f5;
		margin-top: 20px;
	}
	.newsdataProM-img{
		width: 100%;
		height: 350px;
	}
	.newsdataProM-coverageimg{
		width: 102%;
		height: 200px;
		position: absolute;
		bottom: -2px;
		left: -1%;
	}
	.newsdataProM-logoimg{
		width: 35px;
		height: 16px;
		position: absolute;
		bottom: 170px;
		left: 40%;
	}
	.newsdataProM-title{
		width: 100%;
		height: 50px;
		z-index: 99;
		position: absolute;
		bottom: 105px;
		font-size: 15px;
		text-align: center;
		overflow: hidden;
		word-wrap:break-word;
		white-space: pre-wrap;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		padding-left: 2%;
		padding-right: 2%;
	}
	.newsdataProM-message{
		width: 100%;
		height: 15vw;
		z-index: 99;
		position: absolute;
		bottom: 30px;
		font-size: 13px;
		text-align: center;
		padding-left: 2%;
		padding-right: 2%;
		word-wrap:break-word;
		white-space: pre-wrap;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 4;
		-webkit-box-orient: vertical;
	}
	.newsdataProM-date{
		width: 50%;
		position: absolute;
		bottom: 8px;
		margin-left: 25%;
		color: #999;
		font-size: 13px;
	}
	.newsdataProM-date span{
		margin-right: 5px;
	}
	.productionsM-concertbox{
	}
	.productionsM-concert{
		height: 50px;
		line-height: 50px;
		color: #505050;
		white-space: nowrap;
		overflow-y: scroll;
		font-size: 24px;
		color: #505050;
	}
	.theaterProM-yes{
		height: 48px;
		background-color: #FCD002;
		border: solid 1px #B4B7B6;
		display: inline-block;
		margin-right: 3%;
		padding: 0px 20px;
		border-radius: 10px;
	}
	.theaterProM-no{
		height: 48px;
		background-color: #F0EDF1;
		border: solid 1px #B4B7B6;
		display: inline-block;
		margin-right: 3%;
		padding: 0px 20px;
		border-radius: 10px;
	}
	/* 去掉滚动条 */
	::-webkit-scrollbar{
		display: none;
	}
	.news-dataProMbox{
		margin-top:30px;
	}
	.error-img{
		width: 200px;
		height: 150px;
		margin-top: 100px;
	}
	.error-message{
		margin-top: 30px;
		font-size: 20px;
		color: #3B519D;
		margin-bottom: 50px;
	}
	#seaScoll{
		margin-bottom: 30px;
	}
</style>